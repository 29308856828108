<template>
  <div>
    <a-row :gutter="16">
      <a-col :sm="24" :xl="12" :xxl="8" style="margin-bottom: 16px;">
        <a-range-picker :allow-clear="false" :default-value="datetimeRange" :disabled-date="disabledDate"
          :ranges="ranges" :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm"
          @ok="v => { this.datetimeRange = v }"></a-range-picker>
      </a-col>
      <a-col :sm="24" :xl="12" :xxl="16" style="margin-bottom: 16px;">
        <a-button type="primary" style="float:right" @click="() => $refs.itemModal.show(sourceId, selectedData)">配置指标
        </a-button>
      </a-col>
    </a-row>

    <a-row :gutter="16">
      <a-col v-for="chartKey in selectedData" :key="chartKey.key" :xs="24" :xl="12" :xxl="8"
        style="margin-bottom: 16px;">
        <monitor-chart-card :datetime-range="datetimeRange" source-type="database" :chartKey="chartKey"
          @updatekey="updatekey">
        </monitor-chart-card>
      </a-col>
    </a-row>
    <item-modal ref="itemModal" @selectItems="selectItems" assetType="database"></item-modal>
  </div>
</template>

<script>
import moment from 'moment'
import {
  getDatabaseMonitorHistory,
  getDatabasePreferredMonitorItemList,
  updateDatabase
} from '@/api/database'
import ItemModal from '@/components/ItemModal'
import MonitorChartCard from '@/components/chart/MonitorChartCard'
import { getDatetimeRanges } from '@/utils'

export default {
  name: 'HistoryTab',
  components: { ItemModal, MonitorChartCard },
  props: {
    databaseType: { type: String },
    sourceId: { type: String }
  },
  data () {
    return {
      datetimeRange: [moment().subtract(1, 'hour'), moment()],
      ranges: getDatetimeRanges(),
      historyFunc: getDatabaseMonitorHistory,
      selectedData: [],
      params: { page: 1, pageSize: 10 }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    disabledDate (date) {
      return date && date > moment().endOf('day')
    },
    fetch () {
      getDatabasePreferredMonitorItemList(this.sourceId).then((res) => {
        this.selectedData = res.data.data || []
      })
    },
    selectItems (v) {
      this.selectedData = v
    },
    updatekey (chartKey) {
      const index = this.selectedData.findIndex(item => item.key === chartKey.key)
      this.selectedData.splice(index, 1)
      updateDatabase(this.sourceId, { preferred_monitor_items: this.selectedData })
    }
  }
}
</script>
