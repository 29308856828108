<template>
  <div>
    <div style="margin-bottom: 16px;">
      <a-page-header title="数据库" :ghost="false" :sub-title="detail.name">
        <template slot="tags">
          <boolean-status-badge :value="detail.monitored"></boolean-status-badge>
        </template>
        <a-dropdown v-if="permission.update" slot="extra" style="color: #faad14; user-select: none;">
          <a-space>
            <a-icon type="edit"></a-icon>
            <span>编辑</span>
          </a-space>
          <a-menu slot="overlay">
            <a-menu-item>
              <a @click="$refs.baseUpdateForm.show(detail.id)">基本信息</a>
              <base-update-form ref="baseUpdateForm" @ok="fetch"></base-update-form>
            </a-menu-item>
            <a-menu-item>
              <a @click="$refs.authUpdateForm.show(detail.id, detail.database_type)">认证信息</a>
              <auth-update-form ref="authUpdateForm"></auth-update-form>
            </a-menu-item>
          </a-menu>
        </a-dropdown>

        <a-descriptions :column="{ xs: 1, sm: 2, xl: 3, xxl: 4 }">
          <a-descriptions-item label="数据库类型">
            <a-tag color="blue">{{ $t(`database_type.${detail.database_type}`) }}</a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="版本">{{ detail.version }}</a-descriptions-item>
          <a-descriptions-item label="端口">{{ detail.port }}</a-descriptions-item>
          <a-descriptions-item label="所属项目">{{ detail.project ? detail.project.name : '-' }}</a-descriptions-item>
          <a-descriptions-item label="所属主机">
            <a-tooltip v-if="detail.host" placement="topLeft" :title="detail.host.name">
              <a @click="$router.push({ name: 'HostDetail', params: { id: detail.host.id } })">{{ detail.host.name }}</a>
            </a-tooltip>
            <span v-else>-</span>
          </a-descriptions-item>
        </a-descriptions>
      </a-page-header>
    </div>

    <a-card :active-tab-key="activeTabKey" :bordered="false" :tab-list="tabList" @tabChange="key => { this.activeTabKey = key }">
      <history-tab v-show="activeTabKey === 'history'" :database-type="detail.database_type" :sourceId="$route.params.id"></history-tab>
      <alert-event-tab v-show="activeTabKey === 'alertEvent'" :source-id="$route.params.id" source-type="database"></alert-event-tab>
    </a-card>
  </div>
</template>

<script>
import { getDatabase } from '@/api/database'
import BooleanStatusBadge from '@/components/badge/BooleanStatusBadge'
import AlertEventTab from '@/components/tab/AlertEventTab'
import { hasPermission } from '@/utils'
import AuthUpdateForm from '../modules/AuthUpdateForm'
import BaseUpdateForm from '../modules/BaseUpdateForm'
import HistoryTab from './modules/HistoryTab'

export default {
  name: 'DatabaseDetail',
  components: {
    AlertEventTab,
    AuthUpdateForm,
    BaseUpdateForm,
    BooleanStatusBadge,
    HistoryTab
  },
  data () {
    return {
      detail: {
        database_type: 'unknown',
        project: {},
        host: {}
      },
      activeTabKey: 'history',
      tabList: [
        { key: 'history', tab: '监控数据' },
        { key: 'alertEvent', tab: '告警事件' }
      ]
    }
  },
  computed: {
    permission () {
      return {
        update: hasPermission('database.update')
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getDatabase(this.$route.params.id).then(res => {
        this.detail = res.data
      })
    }
  }
}
</script>
